<template>
  <div v-if="scope.row.scale.type">
    <click-to-edit
      v-if="scope.row.scale.type === scaleTypesEnum.TYPE_QUALITY"
      v-model="scope.row.scale.period_fact"
      placeholder="Внесите % выполнения"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.period_fact')"
      style="width: 100%"
      type="textarea"
      @input="saveElementPropChange(scope.row, 'scale', {period_fact:$event})"
    ></click-to-edit>

    <div v-else>{{scope.row.scale.period_fact}}</div>
  </div>


  <div
    v-else
    style="color: #b1b1b2;"
  >
    Сначала выберите тип цели
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-period-fact-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
